import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  headerHeight: '',
};

export const persistSlice = createSlice({
  name: 'persist',
  initialState,
  reducers: {
    onSetHeaderHeight: (state, action) => {
      state.headerHeight = action.payload;
    },
  },
});

export const { onSetHeaderHeight } = persistSlice.actions;

export default persistSlice.reducer;
