import React from 'react';
import { HiOutlineMenu } from 'react-icons/hi';

const NavToggle = ({ toggled, className }) => {
  return (
    <div className={className}>{toggled ? <HiOutlineMenu color="#0D3AA1" /> : <HiOutlineMenu color="#0D3AA1" />}</div>
  );
};

export default NavToggle;
