import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  masterBranchList: {},
};

export const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    onGetListMasterBranchSuccess: (state, action) => {
      state.masterBranchList = action.payload.data;
    },
  },
});

export const { onGetListMasterBranchSuccess } = masterSlice.actions;

export default masterSlice.reducer;
