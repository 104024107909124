import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  customerAnalyticsList: {},
  employeeAnalyticsList: {},
  poolFilter: '',
  rangeFilter: ''
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    onGetListAnalyticsCustomerSuccess: (state, action) => {
      state.customerAnalyticsList = action.payload.data;
    },
    onGetListAnalyticsEmployeeSuccess: (state, action) => {
      state.employeeAnalyticsList = action.payload;
    },
    onSetPoolFilter: (state, action) => {
      state.poolFilter = action.payload;
    },
    onSetRangeFilter: (state, action) => {
      state.rangeFilter = action.payload;
    },
  },
});

export const { onGetListAnalyticsCustomerSuccess, onGetListAnalyticsEmployeeSuccess, onSetPoolFilter, onSetRangeFilter } = analyticsSlice.actions;

export default analyticsSlice.reducer;
