import { combineReducers } from 'redux';
import theme from './theme/themeSlice';
import auth from './auth';
import base from './base';
import persist from './persist/persistSlice';
import vehicle from './vehicle/vehicleSlice';
import analytics from './analytics/analyticsSlice'
import master from './master/masterSlice'

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    persist,
    analytics,
    master,
    vehicle,
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}

export default rootReducer
