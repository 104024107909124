import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  dataAnalytic: null,
  dataFilter: {
    brand: '',
    type: '',
  },
};

export const persistSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    onGetVehicleAnalytic: (state, action) => {
      state.dataAnalytic = action.payload;
    },
    onSetFilter: (state, action) => {
      state.dataFilter.brand = action.payload.brand;
      state.dataFilter.type = action.payload.type;
    },
  },
});

export const { onGetVehicleAnalytic, onSetFilter } = persistSlice.actions;

export default persistSlice.reducer;
